import  { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Case } from '../../models/Case/case';
import { useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth } from '../../redux/slices/authSlice';
import { fetchCasesBatch, onCasesSnapshot } from '../../services/case-data.service';

import "./casemanager.css";
import { Button } from 'react-bootstrap';
import { DocumentSnapshot } from 'firebase/firestore';

const CaseManager = () => {
  const navigate = useNavigate();
  const { userData } = useAppSelector(selectAuth);
  const [cases, setCases] = useState<Case[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showOrgCases, setShowOrgCases] = useState<boolean>(false);
  const [exchangePartner, setExchangePartner] = useState<string>(""); 
  const [filterText, setFilterText] = useState('');
	const [resetPagination, setResetPagination] = useState(false)
  const [lastVisibleDoc, setLastVisibleDoc] = useState<DocumentSnapshot | null>(null);
  const [hasMoreRecords, setHasMoreRecords] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const exchangePartnerFilter = userData?.exchangePartner ?? "AIN";
    setExchangePartner(exchangePartnerFilter);
  
    const email = userData?.email;
    if (email) {
      const batchSize = 50;
      const unsubscribe = onCasesSnapshot(email, showOrgCases, batchSize, (querySnapshot) => {
        const liveCases = querySnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() } as Case));
        setCases(liveCases);
        setLastVisibleDoc(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
        setHasMoreRecords(querySnapshot.docs.length === batchSize)
        setLoading(false);
      });
  
      return () => unsubscribe();
    }
  }, [userData, showOrgCases]);

  const loadMoreCases = useCallback(async () => {
    if (!lastVisibleDoc) return;  // If no more documents are left, skip fetching
    setLoading(true);

    const email = userData?.email;
    if (email) {
      const batchSize = 500;
      const querySnapshot = await fetchCasesBatch(email, showOrgCases, batchSize, lastVisibleDoc);
      const additionalCases = querySnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() } as Case));
      setCases(prevCases => [...prevCases, ...additionalCases]);
      setLastVisibleDoc(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
      setHasMoreRecords(querySnapshot.docs.length === batchSize);
      setLoading(false);
    }
  }, [lastVisibleDoc, userData, showOrgCases]);

  const handleViewCase = (uid: string) => navigate(`/case/${uid}`);
  const handleSendCase = (uid: string, appId: string) => navigate(`/sendqueue/${uid}/${appId}`);

  const filteredCases = cases.filter(caseItem =>
    caseItem.lastName?.toLowerCase().includes(filterText.toLowerCase())
  );

  const getExchangeName = (): string => {
    if (exchangePartner === 'LS') {
      return 'Lion Street Exchange';
    }
  
    return 'AIN Exchange';
  };

  const columns: any[] = [
    {  
      name: 'First Name',  
      selector: (row: { firstName: any; }) => row.firstName,
      sortable: true,
    },
    {  
      name: 'Last Name',  
      selector: (row: { lastName: any; }) => row.lastName,
      sortable: true,
    },
    {  
      name: 'DOB',  
      selector: (row: { dob: any; }) => {
        if (row.dob) {
          const [year, month, day] = row.dob.split('-');
          return `${month}/${day}/${year}`;
        }
        return '';
      },
    },
    {  
      name: 'Summary Status',
      cell: (row: { summaryStatus: string; appId: string }) => (
        row.summaryStatus === 'Completed' ? (
          <a href={`/case/${row.appId}/summary`}>
            {row.summaryStatus}
          </a>
        ) : (
          row.summaryStatus
        )
      ),
    },
    {
      name: 'Informal Inquiry',
      cell: (row: { uid: string; inquiryStatus: string }) => (
        <div className="left-align">
        <button
          onClick={() => handleViewCase(row.uid)}
          className={`inquiry-button ${row.inquiryStatus !== 'Completed' ? 'incomplete-status' : ''}`}
        >
          {row.inquiryStatus !== 'Completed' ? 'Start' : 'Revisit'}
        </button>
      </div>
      ),
    },
    {
      name: getExchangeName(),
      cell: (row: { uid: string, appId: string }) => (
        <div className="left-align">
          <button onClick={() => handleSendCase(row.uid, row.appId)} className="inquiry-button">Shop</button>
        </div>
      ),
    }
  ];

  const subHeaderComponentMemo = useMemo(() => {
		const clearFilter = () => {
			if (filterText) {
				setResetPagination(!resetPagination);
				setFilterText('');
			}
		};

		return (
			<>
				<input
					id="search"
					type="text"
					placeholder="Filter By Last Name"
					aria-label="Search Input"
					value={filterText}
					onChange={e => setFilterText(e.target.value)}
					className="search-input"
				/>
				<Button type="button" onClick={clearFilter} className="reset-button">X</Button>
			</>
		);
	}, [filterText, resetPagination]);

  return (
    <div className='casemanager-container'>      
      {loading && <h1>Loading...</h1>}
      {!loading && 
        <div className='data-table'>
          <div className="form-section">
            <h2>Case Manager</h2>
            <label>
              <input
                type="checkbox"
                checked={showOrgCases}
                onChange={(e) => setShowOrgCases(e.target.checked)}
              />
              &nbsp;
              Show all cases for organization
            </label>
          </div>
          <DataTable
            columns={columns}
            data={filteredCases}
            pagination
            highlightOnHover
            paginationPerPage={10}
            subHeader
			      subHeaderComponent={subHeaderComponentMemo}
          />
          {hasMoreRecords && (
            <Button 
              className='load-button' 
              onClick={loadMoreCases} 
              disabled={loading}
            >
              Load More
            </Button>
          )}
        </div>
      }
      
    </div>
  );
};

export default CaseManager;
