import { NavLink } from 'react-router-dom';

import "./subHeader.css";

import { useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth } from '../../redux/slices/authSlice';

const SubHeader = () => {  
  const { userData } = useAppSelector(selectAuth);
  
  return (
    <> { userData && 
      <nav className="subnavbar navbar-expand">
        <div className="navbar-nav justify-content-center">
          <li className="subnav-item">
            <NavLink to="/" className={({ isActive }) => isActive ? 'sub-nav-link sub-nav-link-active' : 'sub-nav-link' } end>
              Home
            </NavLink>
          </li>
          <li className="subnav-item">
            <NavLink to="/casemanager" className={({ isActive }) => isActive ? 'sub-nav-link sub-nav-link-active' : 'sub-nav-link' } end>
              Case Manager
            </NavLink>
          </li>
          <li className="subnav-item">
            <NavLink to="/caseupload" className={({ isActive }) => isActive ? 'sub-nav-link sub-nav-link-active' : 'sub-nav-link' } end>
              Upload New Case
            </NavLink>
          </li>
          <li className="subnav-item">
            <NavLink to="/orderrecords" className={({ isActive }) => isActive ? 'sub-nav-link sub-nav-link-active' : 'sub-nav-link' } end>
              Order Records
            </NavLink>
          </li>
        </div>
      </nav>
      }
    </>
  );
};

export default SubHeader;

