import { onAuthStateChanged, createUserWithEmailAndPassword, User as FirebaseUser } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { User } from "../models/User";
import { auth, db } from "../firebaseConfig";

export const registerUser = async (registerInput: { firstname: string; lastname: string; email: string; password: string; agency: string; }, exchangePartner: string) => {
  const userCredential = await createUserWithEmailAndPassword(auth, registerInput.email, registerInput.password);
  const user = userCredential.user;

  // Update the user's profile with the display name
  //await updateProfile(user, { displayName: `${values.firstname} ${values.lastname}` });

  // Create a document in Firestore with additional user information
  const userData: User = {
    uid: userCredential.user.uid,
    firstName: registerInput.firstname,
    lastName: registerInput.lastname,
    email: registerInput.email,
    agency: registerInput.agency,
    exchangePartner: exchangePartner,
    createdAt: new Date(),
  };

  await setDoc(doc(db, "users", user.uid), userData);
};

export const getUserData = async (user: FirebaseUser) => {
  const userDocRef = doc(db, "users", user.uid);
  const userDoc = await getDoc(userDocRef);

  if (userDoc.exists()) {
    return userDoc.data();
  } else {
    console.log("No such document!");
    return null;
  }
};

export const onAuthStateChange = (callback: (user: FirebaseUser | null) => void) => {
  onAuthStateChanged(auth, callback);
};
