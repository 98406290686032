import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login/Login";
import Register from "./pages/auth/Register/Register";
import Reset from "./pages/auth/Reset";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Home from "./pages/Home/Home";
import Header from "./components/Header/header";
import { Toaster } from "react-hot-toast";
import SubHeader from "./components/SubHeader/subHeader";
import UnauthorizePage from "./pages/auth/unauthorize";
import CaseManager from "./components/CaseManager/casemanager";
import CaseDetail from "./components/CaseDetail/casedetail";
import { RequireAuth } from "./components/RequireAuth/requireAuth";
import SendQueue from "./components/SendQueue/sendqueue";
import OrderRecords from "./components/OrderRecords/orderrecords";
import CaseUpload from "./components/CaseUpload/caseupload";
import { AuthManager } from "./utils/AuthManager";
import DocumentViewer from "./components/DocumentViewer/documentviewer";

function App() {
  return (
    <BrowserRouter>
      <AuthManager />
      <Header></Header>
      <SubHeader></SubHeader>
      <Routes>
        <Route path='/' element={<Home />}>
          {/* <Route element={<RequireUser allowedRoles={[ROLE_TYPES.USER, ROLE_TYPES.MODERATOR, ROLE_TYPES.SUMMARIZER, ROLE_TYPES.ADMIN]} />}>
            <Route path='profile' element={<ProfilePage />} />
          </Route> */}
          <Route path='unauthorized' element={<UnauthorizePage />} />
        </Route>
        {/* <Route>
          <Route path='register' element={<Register />} />
        </Route> */}
        <Route>
          <Route path='register' element={<Home />} />
          <Route path='register/:partner' element={<Register />} />
        </Route>
        <Route>
          <Route path='login' element={<Login />} />
        </Route>
        <Route>
          <Route path='reset' element={<Reset />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[]} />}>
          <Route path="/casemanager" element={<CaseManager />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[]} />}>
          <Route path="/caseupload" element={<CaseUpload />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[]} />}>
          <Route path="/case/:uid" element={<CaseDetail />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[]} />}>
          <Route path="/case/:uid/summary" element={<DocumentViewer />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[]} />}>
          <Route path="/sendqueue/:uid/:appId" element={<SendQueue />} />
        </Route>
        <Route>
          <Route path='/orderrecords' element={<OrderRecords />} />
        </Route>
        {/* <Route exact path="/protected">
          <Secret />
        </Route>
        <Route exact path="/">
          <Home />
        </Route> */}
      </Routes>
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
