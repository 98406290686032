import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux-hooks";
import { selectAuth } from "../../redux/slices/authSlice";

export const RequireAuth = ({ allowedRoles }: { allowedRoles: string[] }) => {
  //let user = useAppSelector((state: any) => state.userState.user);  
  const { userData } = useAppSelector(selectAuth);

  const location = useLocation();

  let authorized = true;
  // allowedRoles.forEach(allowedRole => {
  //   if (user?.roles.includes(allowedRole)) {
  //     authorized = true;
  //   }
  // });

  return userData && authorized ? (
    <Outlet />
  ) : userData && !authorized ? (
    <Navigate to='/unauthorized' state={{ from: location }} replace />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
}